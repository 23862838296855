var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "vo-items-center" },
            [
              _c("b-col", { attrs: { lg: "4" } }, [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center" },
                  [
                    _c("ReferenceBy", { attrs: { label: "REFINED BY" } }),
                    _c("b-input", {
                      staticStyle: { flex: "1", "margin-left": "10px" },
                      attrs: { placeholder: "Search Product" },
                      model: {
                        value: _vm.search_condition.deviceName,
                        callback: function($$v) {
                          _vm.$set(_vm.search_condition, "deviceName", $$v)
                        },
                        expression: "search_condition.deviceName"
                      }
                    })
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticStyle: { "margin-top": "4px" } },
            [
              _c(
                "b-col",
                { staticStyle: { "margin-left": "120px" } },
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.statusOptions },
                    model: {
                      value: _vm.search_condition.status,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "status", $$v)
                      },
                      expression: "search_condition.status"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.brandOpton },
                    model: {
                      value: _vm.search_condition.brandId,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "brandId", $$v)
                      },
                      expression: "search_condition.brandId"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: { id: "oil-type", options: _vm.types.oilType },
                    model: {
                      value: _vm.search_condition.oilType,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "oilType", $$v)
                      },
                      expression: "search_condition.oilType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "product-type",
                      options: _vm.types.productType
                    },
                    model: {
                      value: _vm.search_condition.type,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "type", $$v)
                      },
                      expression: "search_condition.type"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "activation-type",
                      options: _vm.types.activationType
                    },
                    model: {
                      value: _vm.search_condition.activationType,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "activationType", $$v)
                      },
                      expression: "search_condition.activationType"
                    }
                  })
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c("b-form-select", {
                    attrs: {
                      id: "available-tank-size",
                      options: _vm.types.availableTankSize
                    },
                    model: {
                      value: _vm.search_condition.taskCapacityId,
                      callback: function($$v) {
                        _vm.$set(_vm.search_condition, "taskCapacityId", $$v)
                      },
                      expression: "search_condition.taskCapacityId"
                    }
                  })
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  { staticClass: "vo-row vo-items-center vo-justify-between" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "custom-color cursor",
                        on: { click: _vm.clearAll }
                      },
                      [_vm._v("Clear All")]
                    ),
                    _c(
                      "b-button",
                      { attrs: { variant: "info" }, on: { click: _vm.search } },
                      [_vm._v("Search")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "table-responsive mb-0 box-list" },
            [
              _c(
                "b-row",
                {
                  staticStyle: { margin: "0" },
                  attrs: {
                    "cols-lg": "5",
                    "cols-sm": "2",
                    "cols-xl": "6",
                    "cols-md": "4"
                  }
                },
                _vm._l(_vm.products, function(item, index) {
                  return _c(
                    "b-col",
                    {
                      key: index,
                      staticClass: "cursor",
                      staticStyle: { margin: "20px 0 0 0" }
                    },
                    [
                      item.viewType === "add"
                        ? [
                            _c("CreateProduct", {
                              attrs: { name: "Create SKU" },
                              on: { click: _vm.gotoAdd }
                            })
                          ]
                        : _c("BrandProductItem", {
                            attrs: { item: item },
                            on: {
                              clickStatus: function($event) {
                                return _vm.clickStatus(item)
                              },
                              clickDetail: function($event) {
                                return _vm.clickDetail(item)
                              },
                              clickEdit: function($event) {
                                return _vm.clickEdit(item)
                              },
                              clickFlavor: function($event) {
                                return _vm.clickFlavor(item)
                              },
                              clickCurves: function($event) {
                                return _vm.clickCurves(item)
                              },
                              clickBatch: function($event) {
                                return _vm.clickBatch(item)
                              }
                            }
                          })
                    ],
                    2
                  )
                }),
                1
              )
            ],
            1
          ),
          _vm.has_data
            ? _c(
                "b-row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "dataTables_paginate paging_simple_numbers float-right"
                      },
                      [
                        _vm.rows != 0
                          ? _c(
                              "ul",
                              { staticClass: "pagination mb-0" },
                              [
                                _c("li", [
                                  _c(
                                    "p",
                                    {
                                      staticClass: "pagination mb-0",
                                      staticStyle: {
                                        "margin-top": "7px",
                                        "margin-right": "20px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.rows) + " results "
                                      )
                                    ]
                                  )
                                ]),
                                _c("b-pagination", {
                                  attrs: {
                                    "per-page": _vm.btnMenu(
                                      "brandProductMgmt::Create"
                                    )
                                      ? 11
                                      : 12,
                                    "total-rows": _vm.rows,
                                    "aria-controls": "table"
                                  },
                                  model: {
                                    value: _vm.search_condition.pageNum,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.search_condition,
                                        "pageNum",
                                        $$v
                                      )
                                    },
                                    expression: "search_condition.pageNum"
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  ])
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: {
            imageSrc: require("@/assets/confirm.svg"),
            okBtn: "Yes",
            noBtn: "No"
          },
          on: { ok: _vm.disabledOrEnbleProduct, cancel: _vm.cancel },
          model: {
            value: _vm.showAlert,
            callback: function($$v) {
              _vm.showAlert = $$v
            },
            expression: "showAlert"
          }
        },
        [_c("p", [_vm._v(_vm._s(_vm.showContent))])]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel1 },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_c("p", [_vm._v("Saved successfully")])]
      ),
      _c("ProductDetail", {
        attrs: { noBtn: "OK", productDetail: _vm.productDetail },
        on: { cancel: _vm.cancel2 },
        model: {
          value: _vm.showDetailAlear,
          callback: function($$v) {
            _vm.showDetailAlear = $$v
          },
          expression: "showDetailAlear"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }